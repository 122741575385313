// Generate background classes for different colors alpha levels
@each $alpha-key, $alpha-value in $alpha-levels {
  @each $color-key, $color-value in $theme-colors {
    .bg-#{$color-key}-#{$alpha-key} {
      background-color: rgba($color-value, $alpha-value) !important;
    }
  }
}

@each $name, $hex in $grays {
  .bg-gray-#{$name} {
    background-color: $hex !important;
  }
}