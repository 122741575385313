$until: 50;
$unit: 5;

@while $unit <= $until {
  .mt-n-#{$unit} {
    margin-top: -#{$unit}px;
  }
  .mb-n-#{$unit} {
    margin-top: -#{$unit}px;
  }
  .ms-n-#{$unit} {
    margin-top: -#{$unit}px;
  }
  .me-n-#{$unit} {
    margin-top: -#{$unit}px;
  }
  $unit: $unit + 5;
}
