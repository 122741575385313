@each $alpha-key, $alpha-value in $alpha-levels {
  @each $color-key, $color-value in $theme-colors {
    .text-#{$color-key}-#{$alpha-key} {
      color: rgba($color-value, $alpha-value);
    }
  }
}

// All gray shades colors
@each $color-key, $color-value in $grays {
  .text-#{$color-key} {
    color: $color-value;
  }
}
