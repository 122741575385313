/*!
 * Master theme style scss file
 */
@import "setup/bootstrap-variables";
@import "setup/theme-variables";

@import '../../node_modules/bootstrap/scss/bootstrap';

// Mixins

// Utilities
@import "utilities/button";
@import "utilities/background";
@import "utilities/text";
@import "utilities/spacing";

// Base
@import "base/html";
@import "base/body";
@import "base/headings";

// Components
